/* form { 
  width: 50%;
  background-color: cadetblue;
  border-radius: 8px;
  margin: auto; 
} */
/* .new-lead-items {
  text-align: center 
} */
h1 {
  color: black;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
}




.second-label{
  font-weight: 300;
  margin-bottom: 8px;
  line-height: 20px;
  display: block;
  font-size: 20px;
}

.new-contact-item{
  padding-bottom: 20px;
}




.new-lead-submit{
  text-align: center;
  padding-bottom: 40px;
  padding-top: 20px;
}
#dropdown{
  background-color: #fff;
    border-color: #d9d9d9 #ccc #b3b3b3;
    border-radius: 4px;
    border: 1px solid #ccc;
    color: #333;
    cursor: default;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    height: 36px;
    outline: none;
    overflow: hidden;
    position: relative;
    width: 50%;
}

.new-contact-item.company.invalid input {
  border-color: red;
}

.new-contact-item.full-name.invalid input {
  border-color: red;
}
