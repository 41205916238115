.container-first-step{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    width: 738px;
    height: 394px;
    background: #ffffff;
    box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
    border-radius: 14px; 
}

.text-container{
  padding: 48px 56px !important;
}

.popup-title {
  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #323338 !important;
}

.popup-sm-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #686878;
  padding-top: 14px;
}

.popup-pic-container {
  background: #f8f8f8;
  border-radius: 0px 0px 12px 12px;
  height: 386px !important;
  margin-top: 5px !important;
  margin-left: -18px;
}

.popup-pic{
  width: 212px;
  height: 189px;
  margin-top: 82px;
  background: url("../img/first_step_img.png");
  background-repeat: no-repeat;
  margin-left: 18px;
}

.popup-pic-text{
    padding: 0px 15px 0px 15px;
    color: #323338;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
}

.new-contact-items {
  padding-top: 32px;
}

label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #323338;
  padding-bottom: 10px;
}

button {
  background: #f5f6f9;
  border-radius: 4px !important;
  width: 360px;
  padding: 12px 12px;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  width: 390px;
  text-align: center;

  color: #c3c4cb;
}

input {
  height: 40px;
  outline: none;
  border: 1px solid #C5C7D0;
  box-sizing: border-box;
  border-radius: 4px;
  width: 390px;
  padding: 12px 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #676879;
}


.checkbox-account{
    border: none !important;
    margin-left: 80px !important;
}

.checkbox-account span {
    color: #323338 !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

input.valid-email{
    border: 1px solid #0084FF;
    color: #323338 !important;
}

button.valid-email{
    background: #0073EA;
    border-radius: 4px !important;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 16px;
}

.error-text {
    font-weight: bold;
    color:red;
    white-space:nowrap;
}

.checkbox-account{
  display: inline-block;
  border: 1px solid #c5c7d0;
  box-sizing: border-box;
  border-radius: 38px;
  margin-top: 16px;
  margin-left: 8px;
  padding-left: 16px;
  padding-right: 16px;
  height: 38px;
}

.checkbox-account .input-checkbox {
  width: 16px;
  border: 1px solid #c5c7d0;
  box-sizing: border-box;
  border-radius: 2px;
  margin-top: -2px;
}

.checkbox-item span {
  align-items: center;
  text-align: center;
  color: #676879;
  float: right;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
}

.checkbox-item.checked {
  border: 1px solid #5559DF;
}
