form {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  /* display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start; */
  padding: 48px 56px;
  position: absolute;
  width: 738px;
  height: 394px;
  background: #ffffff;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
  border-radius: 14px;
}
