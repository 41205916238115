.container-success-step {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 48px 40px;
  position: absolute;
  width: 846px;
  height: 502px;
  background: #ffffff;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
  border-radius: 14px;
}

.success-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #323338;
  margin-top: 48px !important;
}

.success-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #323338;
  margin-top: 32px !important;
}

.success-logo{
        position: static;
        height: 71px;
        left: 56px;
        top: 48px;
        background: url("../img/success_img.svg");
        margin-left: 12px;
        background-repeat: no-repeat;
        margin-left: 260px;
        margin-top: 80px !important;
}
