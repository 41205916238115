.container-second-step {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  width: 738px;
  height: 444px;
  background: #ffffff;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
  border-radius: 14px;
}

.popup-pic-container-second {
  background: #f8f8f8;
  border-radius: 0px 0px 12px 12px;
  height: 436px !important;
  margin-top: 5px !important;
  margin-left: -18px;
}

.row-buttons {
  width: 415px;
  margin-top: 12px !important;
}

.row-buttons button {
  width: 97px;
  height: 38px;
  padding: 0px;
}

.continue-button-wrapper button {
  float: right;
}

input.valid-full-name {
  border: 1px solid #0084ff;
  color: #323338 !important;
}

input.valid-account-name {
  border: 1px solid #0084ff;
  color: #323338 !important;
}

button.valid-second-step {
  background: #0073ea;
  border-radius: 4px !important;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
}

.popup-pic-second {
  width: 212px;
  height: 189px;
  margin-top: 96px;
  background: url("../img/second_step_img.png");
  background-repeat: no-repeat;
  margin-left: 29px;
}
