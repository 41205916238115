.container-fourth-step {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 48px 40px;
  position: absolute;
  width: 846px;
  height: 622.64px;
  background: #ffffff;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
  border-radius: 14px;
}

.checkbox-item-fourth {
  display: inline-block;
  border: 1px solid #c5c7d0;
  box-sizing: border-box;
  border-radius: 38px;
  margin-top: 16px;
  margin-left: 8px;
  padding-left: 16px;
  padding-right: 16px;
  height: 38px;
}

.checkbox-container {
  padding-top: 20px;
  padding-bottom: 70px;
}

.row-buttons-fourth{
    padding-left: 10px;
}

.list-title-fourth{
    padding-left: 10px;
}

.new-contact-items-fourth{
  padding-top: 64px;
}

.checkbox-item-fourth .input-checkbox {
  -webkit-appearance: none;
  padding: 7px;
  border-radius: 2px;
  width: 2px !important;
  height: 2px !important;
  border: 1px solid #C5C7D0;
  background-color: white;
  margin-top: 10px;
}

.checked .input-checkbox{
    background-color: #5559DF !important;
    background: url("../img/checked.png");
    background-repeat: no-repeat;
    background-size: 11px;
    background-position-x: 2px;
    background-position-y: 2px;

}



.checkbox-item-fourth span {
  align-items: center;
  text-align: center;
  color: #676879;
  float: right;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
}

button.valid-fourth-step {
  background: #0073ea;
  border-radius: 4px !important;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
}

.checkbox-item-fourth.checked {
  border: 1px solid #5559DF;
}

.checkbox-item-fourth:hover {
  background-color: #e6e9ef;
}

.checkbox-item-fourth.checked:hover {
  background-color: white !important;
}


