.container-third-step {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 48px 40px;
  position: absolute;
  width: 846px;
  height: 622.64px;
  background: #ffffff;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
  border-radius: 14px;
}

.new-contact-items-third{
  padding-top: 64px;
}

.new-contact-item-third{
  padding-top: 15px;
}

.row-buttons-third {
  width: 770px !important;
  margin-top: 12px !important;
  padding-top: 30px;
}

.row-buttons-third button {
  width: 97px;
  height: 38px;
  padding: 0px;
}

li {
  display: inline-block;
  padding: 8px 16px;
  margin: 5px;
  border: 1px solid #C5C7D0;
  box-sizing: border-box;
  border-radius: 38px;
  align-items: center;
  text-align: center;   
}

ul {
    padding-left: 0px !important;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 0px !important;
    cursor: pointer;
}


li:hover {
  background-color: #E6E9EF;
}


.clicked {
  background: #0073EA;
  color: white;
  border: 1px solid #0073EA !important;
}

.clicked:hover{
  background: #0073EA !important;
  color: white;
  border: 1px solid #0073EA !important;
}

.monday-logo {
  position: static;
  width: 135px;
  height: 24.64px;
  left: 56px;
  top: 48px;
  background: url("../img/monday_logo.png");
  margin-left: 20px;
  background-repeat: no-repeat;
}

.list-title {
    position: static;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
  }
  
  .list-second-title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #686878;
    padding-top: 14px;
  }

  strong {
    font-weight: bold  !important;
}

.back-button{
    color:  #323338;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    background: #FFFFFF;
    border: 1px solid #C5C7D0;
    box-sizing: border-box;
    border-radius: 4px;
}

button.valid-Third-step{
    background: #0073EA;
    border-radius: 4px !important;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 16px;
}

